<template>
  <div class="list-shipments">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-body">
              <div class="table-header d-flex justify-content-between">
                <button class="btn btn-primary btn-add" v-on:click="newItem()">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  Adicionar
                </button>
                <input
                  type="text"
                  placeholder="Pesquisar"
                  v-model="filterTerm"
                  class="form-control"
                  v-on:keyup="filterTable()"
                />
              </div>
              <b-table
                hover
                no-local-sorting
                :fields="fields"
                :items="data"
                :per-page="0"
                :current-page="currentPage"
                @sort-changed="sortingChanged"
              >
                <template v-slot:cell(phones)="data">{{
                  data.value.length > 0 ? data.value[0].number : "-"
                }}</template>
                <template v-slot:cell(created_at)="data">{{
                  formatDate(data.value)
                }}</template>
                <template v-slot:cell(status)="data">{{
                  formatStatus(data.value)
                }}</template>
                <template v-slot:cell(file_url)="data">
                  <button
                    class="btn btn-secondary"
                    v-on:click="getLink(data.item)"
                  >
                    Download Arquivo
                  </button>
                </template>
              </b-table>
              <div class="no-data" v-if="data.length == 0">
                <span>Sem dados</span>
              </div>

              <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "@/imports/Helpers";
import { bus } from "@/main";
import axios from "@/api/axios";

export default {
  name: "list-shipments",
  data() {
    return {
      titlePage: "Remessas",
      formType: "create",
      data: [],
      rows: 0,
      createParam: {},
      file_url: [],
      client: {},
      perPage: 10,
      currentPage: 1,
      filter: {
        name: "",
      },
      includes: ["user"],
      sortBy: "",
      filterTerm: "",
      fields: [
        { key: "name", label: "Nome", class: "name-cell", sortable: true },
        { key: "sequencial_copel", label: "Copel", class: "sequencial-cell" },
        { key: "status", label: "Status", class: "status-cell" },
        { key: "user.name", label: "Operador", class: "operator-cell" },
        {
          key: "created_at",
          label: "Data de Envio",
          class: "date-cell",
          sortable: true,
        },
        {
          key: "file_url",
          label: "Arquivo",
          class: "file-cell",
        },
      ],
      navigate_items: [
        {
          text: "Remessas",
          active: true,
        },
      ],
    };
  },
  watch: {
    currentPage: {
      handler: function(value) {
        this.providerData();
      },
    },
  },
  mounted() {
    this.providerData();
  },
  methods: {
    providerData() {
      helper
        .getPaginateQuery(
          "shipments",
          helper.getUrlBuildQuery(
            this.perPage,
            this.currentPage,
            this.filter,
            this.includes,
            this.sortBy
          )
        )
        .then((response) => {
          this.rows = response.data.total;
          this.data = response.data.data;
        });
    },
    filterTable: function() {
      this.filter.filter = this.filterTerm;
      this.currentPage = 1;
      this.providerData();
    },
    formatDate: function(date) {
      return helper.formatDateTime(date);
    },
    formatStatus: function(status) {
      let changedStatus =
        status == "in_progress"
          ? "Em Progresso"
          : status == "failed"
          ? "Falhou"
          : "Concluído";
      return changedStatus;
    },
    newItem: function() {
      this.$router.push({ name: "dashboard.shipment" });
    },
    sortingChanged: function(sortData) {
      this.sortBy = sortData.sortDesc ? `-${sortData.sortBy}` : sortData.sortBy;
      this.currentPage = 1;
      this.providerData();
    },
      getLink: function(data) {
      axios
        .get(`/api/download-shipment/${data.id}`, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.download(url, data.name);
          window.URL.revokeObjectURL(url);
        });
    },
    download: function(url, name) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + ".txt");
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/general.scss";
.list-shipments {
  .name-cell {
    width: 20%;
  }
  .sequencial-cell {
    width: 10%;
  }
  .status-cell {
    width: 20%;
  }
  .operator-cell {
    width: 10%;
  }
  .date-cell {
    width: 20%;
  }
  .file-cell {
    width: 20%;
  }
}
</style>
